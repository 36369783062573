import React from "react"
import oopsConceptImg from "../img/oops-concepts.svg"
import OopsInterviewImg from "../img/oops-concepts.png"
import { Link } from "gatsby"
import NavigationBar from "../components/Navbar"
import { Helmet } from "react-helmet"
import OopsConceptRoll from "../components/OopsConceptRoll"
import loadable from "@loadable/component"

const Scroll = loadable(() => import("../components/scroll"))
const SocialMedia = loadable(() => import("../components/social-media"))
const Footer3 = loadable(() => import("../components/footer3"))

export default class OopsConceptBlogPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Helmet
          htmlAttributes={{
            lang: "en",
          }}
        >
          <meta charSet="utf-8" />
          <title> OOPS Concept Blogs</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta
            name="description"
            property="og:description"
            content="Learn OOP concepts to master object-oriented programming and build modular, reusable, and extensible software applications."
          />
          <meta name="image" property="og:image" content={OopsInterviewImg} />
          <meta name="author" content="Shubham Gautam" />
          <meta
            name="keywords"
            content="oops interview preparation, oops design interview questions, oops concepts, object oriented programming, oops concepts in Java, oops concepts in C++"
          />
          <meta name="title" property="og:title" content="OOPS Concept Blogs" />
        </Helmet>
        <div className="sm:sticky top-0 z-50">
          <NavigationBar />
        </div>

        <div className="bg-light-white">
          <div className="container mx-auto sm:pt-2 px-2 sm:px-3 lg:px-5 2xl:px-7">
            <div className="sm:grid sm:grid-cols-12 sm:gap-4">
              <div className="pt-4 sm:pt-6 lg:pt-8 xl:pt-10 mx-auto text-center sm:text-left sm:col-span-6">
                <h1 className="text-xl lg:text-2xl 2xl:text-3xl text-gray-900  tracking-wider">
                  <span className="">Object Oriented Design</span>
                </h1>
                <ul className="mt-2 sm:mt-3 tracking-wider text-gray-600 text-sm sm:text-xs md:text-sm lg:text-base 2xl:text-lg">
                  <li className="-ml-2 sm:ml-0">
                    Master oop and programming concepts
                  </li>
                  <li className="mt-2 sm:mt-3 md:mt-4">
                    Learn basics of oop design and analysis
                  </li>
                  <li className="ml-1 sm:ml-0 mt-2 sm:mt-3 md:mt-4">
                    Learn oops principles and design pattern
                  </li>
                </ul>
              </div>
              <div className="mt-6 sm:mt-4 sm:col-span-6 flex justify-center">
                <img
                  className="object-cover pointer-events-none w-80 h-44 sm:w-64 sm:h-36 md:w-72 md:h-40 lg:w-96 lg:h-56 2xl:w-112 2xl:h-64"
                  src={oopsConceptImg}
                  alt="OOPS concept blogs enjoyalgorithms"
                />
              </div>
            </div>
            <div className="mt-6 px-1 md:px-3">
              <nav className="flex text-center tracking-wider text-white text-sm sm:text-xs md:text-base xl:text-lg 2xl:text-xl">
                <Link
                  to="/coding-interview/"
                  className="z-30 flex-1 px-1 md:px-2 py-2 rounded shadow border border-new-green bg-white text-new-green hover:bg-new-green hover:text-white transition duration-150"
                >
                  Coding Interview
                </Link>
                <Link
                  to="/machine-learning/"
                  className="z-30 flex-1 px-1 md:px-2 py-2 rounded shadow border border-new-green bg-white text-new-green hover:bg-new-green hover:text-white transition duration-150"
                >
                  Machine Learning
                </Link>
                <Link
                  to="/system-design/"
                  className="z-30 flex-1 px-1 pl-2 pr-2 md:px-2 py-2 rounded shadow border border-new-green bg-white text-new-green hover:bg-new-green hover:text-white transition duration-150"
                >
                  System Design
                </Link>
                <Link
                  to="/oops-concepts/"
                  className="z-30 flex-1 px-1 md:px-2 py-2 text-gray-900"
                >
                  OOPS Concepts
                </Link>
              </nav>
            </div>

            <div className="mt-6 xl:mt-8">
              <OopsConceptRoll />
            </div>

            <Scroll showBelow={250} />
            <SocialMedia />

            <Footer3 />
          </div>
        </div>
      </React.Fragment>
    )
  }
}
